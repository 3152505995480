import {FormControl, InputLabel, OutlinedInput, styled} from "@mui/material";
import TextField from "@mui/material/TextField";

const TextFieldInput = styled(TextField)`
   & .MuiOutlinedInput-root {
       &:not(.Mui-disabled):hover {
           fieldset {
               border-color: rgba(255,255,255, 0.7);
           }
       }
       
       & fieldset {
            border-color: white;
       },
   
       &.Mui-focused {
            & fieldset {
                border-color: white;
            },
       }
   }

   & .MuiOutlinedInput-input {
       color: white;
       
       &.Mui-disabled {
           -webkit-text-fill-color: white;
           opacity: 0.5;
       }
   }
    
   & .MuiInputLabel-root {
       &.Mui-disabled {
           color: white;
           opacity: 0.5;
       }
        
       &.Mui-focused {
           color: white;
       }
   }
    
   & svg {
       fill: white;
   }
    
   .MuiTextField-root {
       color: white;
       font-size: 0.875rem;
       line-height: 1.5rem;
       letter-spacing: 0.009375rem;
       font-family: 'Roboto', sans-serif;
    }
   .MuiInputLabel-root {
       color: white;
       font-size: 0.875rem;
       line-height: 1.5rem;
       letter-spacing: 0.009375rem;
       font-family: 'Roboto', sans-serif;
   }
`

const SelectInputLabel = styled(InputLabel)`
  color: white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  font-family: 'Roboto', sans-serif;
`

const PasswordLabel = styled(InputLabel)`
  color: white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  font-family: 'Roboto', sans-serif;
  
`

const FormControlApp = styled(FormControl)`
  .MuiFormHelperText-root{
    font-family: 'Roboto', sans-serif;
    position: absolute;
    bottom: -25px;
    letter-spacing: .4px;
  }
`

export {TextFieldInput, SelectInputLabel, PasswordLabel, FormControlApp}