import { createTheme } from "@mui/material/styles";
import { defaultTheme } from "./themes/default.theme";
import { mergeDeep } from "./utils";

const overdrivedTheme = {
	typography: {
		h1: {
			color: "#0c001c",
		},
	},
	palette: {
		bgColors: {
			color2: "#0c001c",
			color4: "#385ff0",
			color3: "#385ff0",
			headerBg: "white",
			dashBoardBg: "#08080ae3",
		},
	},
	components: {
		MuiPickersFadeTransitionGroup: {
			styleOverrides: {
				root: {
					"& .MuiDayPicker-weekDayLabel": {
						color: "#385ff0",
					},
				},
			},
		},
		PrivatePickersYear: {
			styleOverrides: {
				root: {
					"& .PrivatePickersYear-yearButton": {
						color: "#385ff0",

						"&.Mui-selected": {
							backgroundColor: "#385ff0 !important",
						},
					},
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: "#385ff0 !important",
					},
					"&.MuiPickersDay-today": {
						borderColor: "#385ff0 !important",
					},
				},
			},
		},
	},
};

const theme = createTheme(mergeDeep(defaultTheme, overdrivedTheme));

export default theme;
