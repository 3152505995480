import React from 'react';
import {Box, Button, Typography} from "@mui/material";
import {Backdrop, StyledModal, StyledModalBox} from "./styled";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

const AppModal = ({isOpen, handleClose, title, errorText}) => {
	const { t, i18n } = useTranslation();

	return(
		<StyledModal
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			BackdropComponent={Backdrop}
		>
			<StyledModalBox>
				<Typography id="modal-modal-title" sx={{color: 'black'}} variant="h6" component="h2">
					{title}
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 1 }}>
					{errorText}
				</Typography>
				{handleClose && <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
					<Button variant={'text'} sx={{color: theme.palette.bgColors.color4}} onClick={handleClose} fullWidth={false}>
						{ t('close') }
					</Button>
				</Box>
				}
			</StyledModalBox>
		</StyledModal>
	)
}

export default AppModal