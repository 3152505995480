import React from 'react'
import {Select} from "@mui/material";
import {SelectInputLabel} from "./styled";
import {customStyles} from "./sharedCustomStyles";

const AppFormSelectWithoutDefault = React.forwardRef(({label, id, onChange, value, children,sx }, ref) => {
	return (
		<>
			<SelectInputLabel id="demo-simple-select-label">{label}</SelectInputLabel>
			<Select
				labelId="demo-simple-select-label"
				id={id}
				value={value}
				label={label}
				onChange={onChange}
				ref={ref}
				sx={{...sx, ...customStyles}}
			>
				{children}
			</Select>
		</>
	)
})

export default AppFormSelectWithoutDefault