import React from 'react'
import {Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {DepositButton, SidebarBox} from "./styled";
import theme from "../../../theme";
import {ReactComponent as TransactionIcon} from './../../../assets/transactionHistoryIcon.svg'
import {ReactComponent as AccountDetailsIcon} from './../../../assets/accountIcon.svg'
import {ReactComponent as WithdrawIcon} from './../../../assets/withdrawIcon.svg'
import {ReactComponent as ManageIcon} from './../../../assets/manageIcon.svg'
import {ReactComponent as DepositIcon} from './../../../assets/depositIcon.svg'
import {
	ACCOUNT_DETAILS_ID,
	DEPOSIT_ID,
	MANAGE_ACCOUNTS_ID,
	TRANSACTION_HISTORY_ID, WEB_TRADER_ID,
	WITHDRAW_ID
} from "../../../constants/constants";
import Withdraw from "../Routes/Withdraw/Withdraw";
import WebTraderList from "../../../components/WebTraderList/WebTraderList";
import {useSelector} from "react-redux";
import {selectClientDetails} from "../../../store/reducers/details/selectors";
import { useTranslation } from "react-i18next";

const tabs = [
	{
		id: TRANSACTION_HISTORY_ID,
		title: 'transactionHistory',
		icon: <TransactionIcon/>,
	},
	{
		id: ACCOUNT_DETAILS_ID,
		title: 'accountDetails',
		icon: <AccountDetailsIcon/>,
	},
	{
		id: WITHDRAW_ID,
		title: 'withdraw',
		icon: <WithdrawIcon/>,
	},
	{
		id: MANAGE_ACCOUNTS_ID,
		title: 'manageAccounts',
		icon: <ManageIcon/>,
	},
]
const depositTab = {
	id: DEPOSIT_ID,
}
const webTraderTabs = {
	id: WEB_TRADER_ID
}

const Sidebar = ({activeTabId, handleClick, isSidebarOpen, accounts}) => {
	const details = useSelector(selectClientDetails)
	const { t, i18n } = useTranslation()
	const activeLink = (activeTabId, tabId) => {
		const isActiveLink = activeTabId === tabId
		return {
			background: isActiveLink ? theme.palette.bgColors.color3 : 'initial',
			/*color: isActiveLink ? theme.palette.primary.main : theme.palette.primary.contrastText,*/
			'svg': {
				g: {
					path: {
						fill: theme.palette.primary.contrastText,
						strokeOpacity: 0,
					}
				},
				path: {
					stroke: theme.palette.primary.contrastText,
				}
			},
		}
	}
	return (
		<SidebarBox className={isSidebarOpen ? 'open' : ''}>
			<Box height={'90%'}>
				<Box p={'24px'} textAlign={'left'}>
					<Typography
						variant='h2'
						sx={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							WebkitLineClamp: '1',
							whiteSpace: 'nowrap'
						}}
					>
						{ t('greeting') }, {details.first_name} {details.last_name}
					</Typography>
				</Box>
				<List>
					{tabs ? tabs.map(tab => (
							<ListItem sx={() => activeLink(activeTabId, tab.id)} key={tab.id}>
								<ListItemButton onClick={() => handleClick(tab.id)}>
									<ListItemIcon>
										{tab.icon}
									</ListItemIcon>
									<ListItemText primary={ t(tab.title) }/>
								</ListItemButton>
							</ListItem>
						)
					) : ''
					}
				</List>
				<WebTraderList>
					{accounts &&
						accounts.map(item => (
							<Button
								key={item.account}
								onClick={() => handleClick(webTraderTabs.id, item.webtrader_url)}
								sx={{textTransform: 'unset'}}
							>
								#{item.account}({item.account_type})
							</Button>
						))
					}
				</WebTraderList>
			</Box>
			<DepositButton
				className={activeTabId === depositTab.id ? 'active' : ''}
				onClick={() => handleClick(depositTab.id)}
			>
				<DepositIcon/>
				{ t('deposit') }
			</DepositButton>
		</SidebarBox>
	)
}

export default Sidebar